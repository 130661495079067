import React from 'react'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Footer from '../components/Footer'
import privacyPolicy from '../data/privacyPolicy.json'

const PrivacyPolicyPage = () => (
  <Layout path="/privacy" title="Privacy Policy - Brentiv - Rental Software">
    <div className="legal-page terms-of-service-page">
      <SmallHeader title="Privacy Policy" />

      <div className="row-spaced legal-page-content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {privacyPolicy.map((item, index) => (
                <div key={index} className="container-padded">
                  <h2 className="title">{item.title}</h2>
                  {item.policies.map((policy, k) => (
                    <p key={k} dangerouslySetInnerHTML={{ __html: policy }} />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default PrivacyPolicyPage
